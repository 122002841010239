APP.footer = function () {
	function init() {
		$('.footer__title').matchHeight({
			property: 'min-height',
		})
		$.each($('.block-content1__body video'), function (index, ele) {
			// remove style of parent element
			$(ele).parent().removeAttr('style')
		})

		$('.abc-center').slick({
			centerMode: true,
			slidesToScroll: 1,
			centerPadding: '40px',
			slidesToShow: 3,
			infinite: true,
			autoplaySpeed: 5000,
			autoplay: true,
			arrow: true,
			prevArrow: "<i class='arrow left'></i>",
			nextArrow: "<i class='arrow right'></i>",

			responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						centerMode: true,
						centerPadding: '40px',
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 480,
					settings: {
						arrows: false,
						centerMode: true,
						centerPadding: '40px',
						slidesToShow: 1,
					},
				},
			],
		})
	}

	init()
}
