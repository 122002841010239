APP.search = function () {
	const el = $('.block-search')
	const header = $('.header')
	const inputSearch = el.find('input')
	const inputSearchHeader = header.find('input')

	function init() {
		const sText = APP.getUrlParameter('s')
		if (sText) {
			inputSearch.val(sText)
			inputSearchHeader.val(sText)
		}
	}

	init()
}
