APP.header = function () {
	const header = $('.header')
	const toggle = header.find('.toggle')
	let lastScrollTop = 0

	const lang = header.find('.lang')
	const langCurrent = lang.find('.lang__current')

	function init() {
		toggle.on('click', handleToggleClick)
		langCurrent.on('click', handleLangClick)
		WINDOW.on('scroll', handleOnScroll)
	}

	function handleToggleClick() {
		$(this).toggleClass(CLASS._show)
		header.toggleClass(CLASS._show)

		if (header.hasClass(CLASS._show)) {
			BODY.addClass(CLASS._noscroll)
		} else {
			BODY.removeClass(CLASS._noscroll)
		}
	}

	function handleLangClick() {
		lang.toggleClass(CLASS._show)
	}

	function handleOnScroll() {
		let scrollTop = $(this).scrollTop()

		if (lastScrollTop <= 0) {
			lastScrollTop = 0
		}

		if (scrollTop > 100) {
			header.addClass(CLASS._move)
		} else {
			header.removeClass(CLASS._move)
		}

		if (scrollTop > lastScrollTop) {
			header.addClass(CLASS._moveHide)
		} else {
			header.removeClass(CLASS._moveHide)
		}

		lastScrollTop = scrollTop
	}

	init()
}
